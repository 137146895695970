<div class="card">
  <div class="card-content col-12">
    <form *ngIf="uccForm" [formGroup]="uccForm">
      <div class="col-12 col-sm-6">
        <!-- Filling Type -->
        <mat-form-field class="col-sm-12 col-md-6">
          <mat-select class="col-md-12" placeholder="Filing Type" formControlName="filingType">
            <mat-option *ngFor="let fillingType of filingTypes" [value]="fillingType">
              {{ fillingType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- END Filling Type -->

        <!-- UCC3 Types -->
        <mat-form-field *ngIf="uccForm.get('filingType').value === 'UCC3'" class="col-sm-12 col-md-6">
          <mat-select class="col-md-12" placeholder="Filing Type" formControlName="uccType">
            <mat-option *ngFor="let uccType of uccTypes" [value]="uccType">
              {{ uccType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- END UCC3 Types -->
      </div>
      <div class="col-sm-12 col-md-6">
        <!-- Filling ID -->
        <mat-form-field class="col-sm-12 col-md-6">
          <input matInput class="col-sm-12 col-md-6" placeholder="Filling ID" aria-label="filling-id" formControlName="filingId" />
        </mat-form-field>
        <!-- END Filling ID -->
      </div>
      <div class="col-sm-12 col-md-6">
        <!-- Filling Date -->
        <mat-form-field class="col-sm-12 col-md-6" ngxErrors="filingDate">
          <input matInput [matDatepicker]="picker" class="calendar" placeholder="Filing date" formControlName="filingDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error ngxError="required" when="touched"> Due date is required </mat-error>
        </mat-form-field>
        <!-- END Filling Date -->

        <!-- Expiration Date -->
        <mat-form-field *ngIf="uccForm.get('filingType').value === 'UCC1'" class="col-sm-12 col-md-6" ngxErrors="expirationDate">
          <input matInput [matDatepicker]="picker" class="calendar" placeholder="Expiration date" formControlName="expirationDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error ngxError="required" when="touched"> Expiration date is required </mat-error>
        </mat-form-field>
        <!-- END Expiration Date -->
      </div>
      <div class="col-sm-12 col-md-6">
        <!-- Filling Number -->
        <mat-form-field class="col-sm-12 col-md-6">
          <input
            matInput
            class="col-sm-12 col-md-6"
            placeholder="Filing number"
            aria-label="Filing-number"
            formControlName="filingNumber"
          />
        </mat-form-field>
        <!-- END Filling Number -->
      </div>
      <div class="mb-4 mt-3">
        <span class="">
          <mat-radio-group class="" formControlName="senior">
            <span class="alignment mr-3">Senior ?</span>
            <mat-radio-button [value]="true">Yes, override</mat-radio-button>
            <mat-radio-button [value]="false">No, do not override</mat-radio-button>
            <mat-radio-button [value]="null">Not Applicable</mat-radio-button>
          </mat-radio-group>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <button
          mat-raised-button
          class="btn-w-md btn-w-md mr-4"
          (click)="redirectToList()"
          [ngClass]="{ 'btn-busy': toggleInProgress }"
          [color]="'info'"
        >
          Cancel
        </button>
        <button
          type="submit"
          (click)="submit()"
          [ngClass]="{ 'btn-busy': toggleInProgress }"
          [disabled]="!uccForm.valid"
          class="btn-w-md btn-w-md submit-btn"
          mat-raised-button
          color="accent"
        >
          <span *ngIf="!uccKey">Add</span>
          <span *ngIf="uccKey">Save</span>
        </button>

        <button
          mat-raised-button
          *ngIf="hasFinancePermission"
          class="btn-w-md btn-w-md ml-4"
          (click)="deleteUcc()"
          [ngClass]="{ 'btn-busy': toggleInProgress }"
          [color]="'warn'"
          [disabled]="!uccKey"
        >
          Delete
        </button>
      </div>
    </form>
  </div>
</div>
