import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { isNullOrUndefined, isUndefined } from 'util';

import { MonitorService } from '../../../../../shared/monitor.service';
import { AppState } from '../../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { SupplierUnderwritingService } from '../../supplier-underwriting.service';
import { UCCModel } from '../ucc.model';
import { UccService } from '../ucc.service';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';
import { USER_PERMISSION } from './../../../../../shared/users/user-permissions.enum';

@Component({
  selector: 'app-ucc-list',
  templateUrl: './ucc-list.component.html',
  styleUrls: ['./ucc-list.component.scss'],
})
export class UccListComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('paginatorTransUnion', { static: true }) paginatorTransUnion: MatPaginator;

  public payabilityDisplayedColumns: string[] = ['Date', 'title', 'subject', 'uccType', 'fillingId', 'senior', 'expirationDate'];
  public displayedColumns: string[] = [
    'filingDate',
    'filingNumber',
    'filingType',
    'debtorName',
    'debtorState',
    'securedPartyName',
    'securedPartyBusinessName',
    'collateralDescription',
  ];
  public dataSource = new MatTableDataSource();
  public transunionDataSource = new MatTableDataSource();
  public pageSizeOptions = [10, 20, 40];
  public transUnionActionStatusKey: string;
  public transUnion: any;
  public isRestrictedUWView = false;

  private supplierKey: string;

  constructor(
    private store: Store<AppState>,
    private uccService: UccService,
    private router: Router,
    private route: ActivatedRoute,
    private supplierUnderwritingService: SupplierUnderwritingService,
    private permissionsService: NgxPermissionsService,
    private monitorService: MonitorService,
  ) {
    super();
  }

  ngOnInit() {
    this.monitorService.setUntracked();
    this.checkUserPermission();
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierData) => {
        if (!isUndefined(supplierData)) {
          this.supplierKey = supplierData.supplierKey;
          this.monitorService.send(this.supplierKey);
          this.setUccList();
          this.checkTransUnionData();
        }
      }),
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.transunionDataSource.paginator = this.paginatorTransUnion;
  }

  formatDate(date?: string): string {
    return date ? moment(date).format('YYYY-MM-DD HH:mm') : '';
  }

  public redirect(ucc?: UCCModel | any): void {
    if (ucc && ucc.hasOwnProperty('supplierUccDataKey')) {
      this.router.navigate(['../transUnion', ucc.supplierUccDataKey], { relativeTo: this.route });

      return;
    }

    ucc
      ? this.router.navigate(['../edit', ucc.supplierUccFilingKey], { relativeTo: this.route })
      : this.router.navigate(['../create'], { relativeTo: this.route });
  }

  public checkTransUnionData(): void {
    this.subscriptions.push(
      this.supplierUnderwritingService
        .getActionsService(this.supplierKey, 'e6247370-bd22-4992-8054-7900a4afa789', 'actions', 'v2')
        .subscribe((data) => {
          const transUnionStatus = data.filter(function (action) {
            return action.compliance_action_type === 'credit_check';
          })[0];

          this.transUnion = transUnionStatus.satisfied_by[0];
          this.transUnionActionStatusKey = this.transUnion.compliance_action_status_key;
        }),
    );
  }

  public isSenior = (isSenior: boolean): string => (isSenior ? 'Yes' : 'No');

  private setUccList(): void {
    this.subscriptions.push(
      this.uccService.getPayabilityUccList(this.supplierKey).subscribe((data) => {
        this.dataSource['data'] = data.results;
      }),
    );

    this.subscriptions.push(
      this.uccService.getTransunionUccList(this.supplierKey).subscribe((data) => {
        this.transunionDataSource['data'] = data.results;
      }),
    );
  }

  private checkUserPermission() {
    const restrictedAccessPermission = this.permissionsService.getPermission(USER_PERMISSION.REST_UW_ACCESS);

    if (!isNullOrUndefined(restrictedAccessPermission)) {
      this.isRestrictedUWView = true;
    }
  }
}
